.primary-btn {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: max-content;
  background-color: $base-color;
  color: #ffffff;
  height: 36px;
  border: none;
  border-radius: 8px;
  text-transform: uppercase;
  &:hover {
    background-color: rgba($base-color, 0.9);
  }
}
.secondary-btn {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: max-content;
  background-color: #ffffff;
  color: #000000;
  height: 36px;
  border: 1px solid rgba($base-color, 0.15);
  border-radius: 8px;
  text-transform: uppercase;
  &:hover {
    border: 1px solid rgba($base-color, 0.45);
  }
  &:disabled {
    background-color: gray;
  }
}

.banjos-accordian {
  position: relative;
  .accordion-item {
    .accordion-header {
      // height: 48px;
      &.sub-head {
        &.sub-item {
          margin-left: 8px;
        }
        .accordion-button {
          font-weight: 400;
          &:not(.collapsed) {
            background-color: unset;
          }
        }
      }
      .accordion-button {
        height: 48px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #000000;
        padding: 12px 16px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        gap: 4px;

        &::after {
          margin-left: unset;
          background: url("../images/arrow-drop-down.svg");
          background-repeat: no-repeat;
          background-position: center;
          width: 24px;
          height: 24px;
        }
      }
    }
    .accordion-body {
      padding: 0;
      .favourite-section-button-editor {
        padding-top: 4px;
        .banjos-input-wrapper {
          border-bottom: none;
        }
      }
    }
  }
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    img {
      margin-right: 5px;
    }
    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 159px;
      text-align: left;
    }
  }
}

.banjos-table {
  width: 100%;
  .banjos-table-header {
    padding: 0 24px;
  }
  .action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    gap: 30px;
    margin-left: auto;
    width: 90px;
    height: 30.75px;
    img {
      width: 30px;
      height: 30px;
      cursor: pointer;
      &:hover {
        border: 1px solid $gray-80;
        border-radius: 8px;
      }
    }
  }
  tr {
    height: 62px;
    border-bottom: 1px solid rgba($base-color, 0.15);
    padding: 0 24px;
    &:last-child {
      border-bottom: none;
    }
    &:first-child {
      border-bottom: 1px solid rgba($base-color, 0.15);
    }
    th {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
      &:first-child {
        padding-left: 24px;
      }
      &:last-child {
        padding-right: 24px;
      }
    }
    td {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      &:first-child {
        padding-left: 24px;
      }
      &:last-child {
        padding-right: 24px;
      }
      .table-link {
        color: #1950a2;
        opacity: 0.65;
        text-decoration: none;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.container {
  &.container-max {
    max-width: 1180px;
    font-family: $base-font;
    @media (min-width: 1400px) {
      max-width: 1300px;
    }
    @media (min-width: 1600px) {
      max-width: 1480px;
    }
  }
}

.call-modal {
  .modal-header {
    padding: 12px 12px 0;
    min-height: 28px;
    border: none;
    .close {
      background: transparent url("../images/close.svg") no-repeat center center;
      padding: 0;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      position: relative;
      cursor: pointer;
      border: none;
      z-index: 1;
    }
  }
  .modal-body {
    .call-middle {
      a {
        color: $base-color;
        text-decoration: none;
        background-color: transparent;
      }
      .head-16 {
        font-family: Grenette Pro;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 19px !important;
      }
      .subtitle-13 {
        font-family: Grenette Pro;
        font-weight: 400 !important;
        line-height: 18px !important;
        font-size: 13px !important;
      }
    }
  }
}

.un-auth {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  z-index: 100000;
  background: #fff;
  font-family: "Roboto";
  .content {
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 140px;
      height: auto;
      margin-bottom: 15px;
    }
    h3 {
      font-size: 28px;
      font-weight: normal;
      line-height: 34px;
      margin: 0;
      margin-top: 1rem;
      margin-bottom: 25px;
    }
  }
}
