.store-landing-wrapper {
  //MainBannerBlock
  .main-banner-block-wrapper {
    position: relative;
    width: 100%;
    height: 288px;
    background: rgba(0, 0, 0, 0.64);
    //for mobile
    .image-wrapper {
      position: relative;
      width: 100%;
      height: 288px;
      object-fit: cover;
      object-position: center;
      mix-blend-mode: overlay;
    }
    .content-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 1;
      width: max-content;
      height: max-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      .store-name {
        font-family: $fancy-font;
        font-style: normal;
        font-weight: 400;
        font-size: 48px;
        line-height: 49px;
        text-align: center;
        margin: 0;
      }
      .open-time-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .open-time-title {
          display: none;
          font-family: $base-font;
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          color: #ffffff;
        }
        .open-times-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          .open-time {
            font-family: $base-font;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #ffffff;
          }
        }
      }
      .buttons-wrapper {
        display: flex;
        flex-direction: row;
        gap: 32px;
        .call-btn,
        .direction-btn {
          width: 40px;
          height: 40px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          border-radius: 80px;
          background-color: $flour-color;
          cursor: pointer;
          &:hover {
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16),
              0px 2px 4px rgba(0, 0, 0, 0.25);
          }
          img {
            height: 24px;
            width: 24px;
          }
          span {
            display: none;
            font-family: $base-font;
            font-weight: 400;
            font-size: 18px;
            line-height: 16px;
            text-align: center;
            text-transform: uppercase;
            color: $base-color;
          }
        }
      }
    }
    //for Desktop
    @media (min-width: 768px) {
      height: 527px;
      .image-wrapper {
        height: 527px;
      }
      .content-container {
        gap: 16px;
        max-inline-size: 75%;
        .store-name {
          font-size: 128px;
          line-height: 85px;
        }
        .open-time-container {
          .open-time-title {
            display: inline;
          }
          .open-times-wrapper {
            .open-time {
              font-size: 20px;
              line-height: 32px;
            }
          }
        }
        .buttons-wrapper {
          gap: 12px;
          .call-btn,
          .direction-btn {
            width: 175px;
            height: 64px;
            gap: 10px;
            span {
              display: block;
            }
          }
          a {
            text-decoration: none;
          }
        }
      }
    }
  }
  //categoryBlock
  .category-block-wrapper {
    //Mobile resposive
    .category-block-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      width: 100%;
      padding: 32px 0;
      background-color: #ffffff;
      .category-block-title {
        .title {
          font-family: $base-font;
          font-weight: 400;
          font-size: 24px;
          line-height: 24px;
        }
      }
      .category-block-items {
        padding: 0 16px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 24px;
        .category-items {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 25%;
          gap: 4px;
          .category-cover-box {
            width: 62px;
            height: 62px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover {
              box-shadow: 0 0 12px rgb(0 0 0 / 30%);
            }
            img {
              width: 37px;
              height: 37px;
            }
          }
          .category-name {
            font-family: $base-font;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $gray-30;
          }
        }
      }
    }
    //Desktop resposive
    @media (min-width: 768px) {
      .category-block-container {
        gap: 44px;
        padding: 74px 0;
        .category-block-title {
          .title {
            font-size: 40px;
            line-height: 32px;
          }
        }
        .category-block-items {
          padding: 0 16px;
          gap: 48.5px;
          justify-content: center;
          .category-items {
            width: unset;
            gap: 8px;
            .category-cover-box {
              width: 120px;
              height: 120px;
              img {
                width: 53px;
                height: 53px;
              }
            }
            .category-name {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }

  //ImageBannerBlock
  .image-banner-block-wrapper {
    //mobile resposive
    .image-banner-block-container {
      position: relative;
      width: 100%;
      height: 178px;
      display: flex;
      .banner-image {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        box-sizing: border-box;
        padding: 0;
        border: none;
        margin: auto;
        display: block;
        width: 0;
        height: 0;
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
      }
      .banner-fancy-text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        z-index: 1;
        background: rgba(0, 0, 0, 0.25);
        font-family: $fancy-font;
        font-size: 48px;
        line-height: 49px;
      }
    }
    //Desktop resposive
    @media (min-width: 768px) {
      .image-banner-block-container {
        height: 376px;
        .banner-fancy-text {
          font-size: 128px;
          line-height: 131px;
        }
      }
    }
  }
  //orderOnlineBlockWrapper
  .order-online-block-wrapper {
    //Mobile resposive
    .order-online-block-container {
      display: flex;
      flex-direction: column;
      padding: 20px 0 24px;
      align-items: center;
      position: relative;
      .order-online-block-title {
        font-family: $base-font;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        max-width: 197px;
      }
      .order-online-block-description {
        font-family: $base-font;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        padding: 0 16px;
        margin: 8px 0;
      }
      .order-online-block-button {
        width: calc(100% - 48px);
        height: 48px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16),
          0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 62px;
        outline: none;
        border: none;
        font-family: $base-font;
        font-size: 18px;
        line-height: 16px;
        text-transform: uppercase;
        cursor: pointer;
      }
    }

    //desktop resposive
    @media (min-width: 768px) {
      .order-online-block-container {
        padding: 58px 0 60px;
        .order-online-block-title {
          font-size: 40px;
          line-height: 53px;
          max-width: calc(100% - 48px);
        }
        .order-online-block-description {
          margin: 8px 0 35px;
          max-width: 708px;
        }
        .order-online-block-button {
          width: fit-content;
          height: auto;
          padding: 24px 31px;
          font-size: 18px;
          line-height: 16px;
        }
      }
    }
  }

  //TextBlock
  .text-block-wrapper {
    //mobile resposive
    .text-block-container {
      height: max-content;
      background-repeat: repeat;
      background-size: 200%;
      background-position: initial;
      display: flex;
      justify-content: center;
      .text-items-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px;
        gap: 24px;
        .text-box-item {
          display: flex;
          flex-direction: column;
          padding: 12px;
          gap: 16px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
          border-radius: 16px;
          width: 100%;
          background-color: $base-color;
          .text-box-item-content {
            font-size: 15px;
            line-height: 20px;
            color: #ffffff;
            ul {
              padding-inline-start: 22px;
            }
            p {
              &:first-child {
                margin-top: 0;
              }
            }
            p,
            ul {
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    //desktop resposive
    @media (min-width: 768px) {
      .text-block-container {
        background-size: 148%;
        .text-items-container {
          padding: 40px 15px;
          gap: 32px;
          .text-box-item {
            padding: 32px;
            .text-box-item-content {
              font-size: 16px;
              line-height: 21px;
              ul {
                padding-inline-start: 24px;
              }
            }
          }
        }
      }
    }
  }
  //FavouriteCategoryBlock
  .favourite-category-block-wrapper {
    //Mobile Resposive
    .favourite-category-block-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 33px 0 24px 0;
      gap: 16px;
      .favourite-category-block-head {
        font-size: 24px;
        line-height: 32px;
      }
      .favourite-category-block-items {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 16px;
        .favourite-category-block-item {
          display: flex;
          flex-direction: column;
          padding: 12px;
          gap: 24px;
          border: 1px solid $gray-90;
          border-radius: 12px;
          .first-col {
            display: flex;
            flex-direction: column;
            gap: 16px;
            .category-block-title {
              font-size: 18px;
              line-height: 24px;
            }
            .category-block-des {
              font-size: 16px;
              line-height: 21px;
              p {
                &:first-child {
                  margin-top: 0;
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
          .secound-col {
            align-self: flex-end;
            .category-block-btn {
              width: max-content;
              height: fit-content;
              padding: 16px 24px;
              box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16),
                0px 2px 4px rgba(0, 0, 0, 0.25);
              border-radius: 62px;
              outline: none;
              border: none;
              font-family: $base-font;
              font-size: 18px;
              line-height: 16px;
              text-transform: uppercase;
              cursor: pointer;
            }
          }
        }
      }
    }
    //desktop Resposive
    @media (min-width: 768px) {
      .favourite-category-block-container {
        padding: 108px 0;
        gap: 44px;
        .favourite-category-block-head {
          font-size: 40px;
          line-height: 53px;
        }
        .favourite-category-block-items {
          flex-direction: column;
          gap: 44px;
          padding: 0 15px;
          .favourite-category-block-item {
            flex-direction: row;
            justify-content: space-between;
            padding: 24px;
            gap: 71px;
            border: 2px solid $gray-90;
            border-radius: 12px;
            .first-col {
              .category-block-title {
                font-size: 24px;
                line-height: 24px;
              }
            }
            .secound-col {
              align-self: center;
              .category-block-btn {
                width: 220px;
                padding: 24px 24px;
              }
            }
          }
        }
      }
    }
  }

  //WeAreAvailableBlock
  .we-are-available-block-wrapper {
    //mobile responsive
    .we-are-available-block-container {
      padding: 24px 16px 12px;
      .we-are-available-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 12px;
        gap: 20px;
        border: 1px solid #d9d9d9;
        border-radius: 12px;
        background-color: $gray-97;
        .we-are-available-title {
          color: #000000;
          font-size: 24px;
          line-height: 32px;
        }
        .we-are-available-items {
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 16px;
          flex-wrap: wrap;
          row-gap: 34px;
          .we-are-available-item {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 8px;
            width: 150px;
            height: 48px;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
            border-radius: 62px;
            cursor: pointer;
            img {
              max-height: 48px;
              max-width: 118px;
            }
          }
        }
      }
    }

    //desktop resposive
    @media (min-width: 768px) {
      .we-are-available-block-container {
        padding: 12px 16px;
        display: flex;
        justify-content: center;
        .we-are-available-box {
          padding: 32px 15px;
          gap: 34px;
          width: 100%;
          .we-are-available-title {
            font-size: 40px;
            line-height: 53px;
          }
          .we-are-available-items {
            .we-are-available-item {
              width: 175px;
              height: 64px;
              img {
                max-height: 64px;
                max-width: 159px;
              }
            }
          }
        }
      }
    }
  }
  .proudly-support-block-wrapper {
    //ProudlySupportBlock
    .proudly-support-block-container {
      .proudly-support-block-flexbox {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 16px;
        gap: 20px;
        .proudly-support-block-title {
          font-size: 24px;
          line-height: 32px;
        }
        .proudly-support-block-items {
          display: flex;
          flex-direction: row;
          gap: 56px;
          flex-wrap: wrap;
          row-gap: 32px;
          justify-content: center;
          img {
            height: 51px;
            width: auto;
            max-width: 150px;
          }
        }
      }
    }

    //desktop resposive
    @media (min-width: 768px) {
      .proudly-support-block-container {
        .proudly-support-block-flexbox {
          padding: 40px 16px 47px;
          gap: 55px;
          .proudly-support-block-title {
            font-size: 40px;
            line-height: 53px;
          }
          .proudly-support-block-items {
            gap: 94px;
            img {
              height: 65px;
              max-width: 160px;
            }
          }
        }
      }
    }
  }
}
