@font-face {
  font-family: "Grenette Pro";
  src: url("../fonts/GrenettePro-Regular.woff2") format("woff2"),
    url("../fonts/GrenettePro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Oranges And Lemons Script";
  src: url("../fonts/OrangesAndLemons.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
