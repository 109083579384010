// Fonts
$base-font: "Grenette Pro";
$title-font: "Grenette Pro";
$code-font: "Grenette Pro";
$fancy-font: "Oranges And Lemons Script";

// Colors
$nav-link: #000000;
$error-color: #5e7360;
$sucess-color: #4caf50;
$primary-color: #d7a946;
$primary-font-color: #060606;
$secondary-font-color: #9d9d9d;
$base-color: #5e7360;
$secondary-color: #747d42;
$add-button-color: #e8fae8;
$primary-green-color: #5e7360;
$color-primary-dark: #3b513d;
$primary-link-text-color: #4f3e3b;
$color-primary-light: #e8fae8;

$button-hover-color: #5e7360de;
$flour-color: #fffceb;
$russet-color: #a04d3d;
$color-toast: #564845;
$error-red: #c60808;

//gray colors

$gray-30: #4d4d4d !default;
$gray-40: #666666 !default;
$gray-50: #808080 !default;
$gray-60: #999999 !default;
$gray-80: #cccccc !default;
$gray-90: #e6e6e6 !default;
$gray-95: #f2f2f2 !default;
$gray-97: #f7f7f7 !default;