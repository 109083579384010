.banjos-input-wrapper {
  padding: 0 16px 16px;
  border-bottom: 1px solid rgba($base-color, 0.25);
  .banjos-input-text,
  .banjos-input-des,
  .banjos-input-color,
  .banjos-input-image {
    &:first-child {
      margin-top: 16px;
    }
  }
  //Label
  .banjos-input-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    img {
      cursor: pointer;
    }
  }
  //Description
  .banjos-input-des {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    height: 16px;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.25px;
    color: $gray-50;
    margin-bottom: 2px;
  }
  //Text Input
  .banjos-input-text {
    outline: none;
    height: 48px;
    width: 100%;
    background: #ffffff;
    border: 1px solid rgba($base-color, 0.25);
    border-radius: 8px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $gray-50;
    padding: 16px 12px;
    &.textarea {
      height: 121px;
    }
    &:focus {
      border: 1px solid $base-color;
    }
  }
  //Color Input
  .banjos-input-color {
    width: 100%;
    outline: none;
    height: 58px;
    border: 1px solid rgba($base-color, 0.25);
    padding: 8px;
    border-radius: 8px;
    margin-top: 8px;
    input {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      border: none;
      overflow: hidden;
      padding: 0;
      outline: 1px solid rgba($base-color, 0.15);
      &::-webkit-color-swatch {
        border: none;
      }
    }
    &:focus-within {
      border: 1px solid $base-color;
    }
  }
  //Image upload
  .banjos-input-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 35px;
    gap: 8px;
    height: 104px;
    background: #ffffff;
    border: 1px solid rgba($base-color, 0.15);
    border-radius: 8px;
    span {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $gray-50;
    }
    img {
      width: 28px;
      height: 28px;
    }
    .image-loading {
      border: 5px solid #f3f3f3;
      -webkit-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite;
      border-top: 5px solid $base-color;
      border-radius: 50%;
      width: 50px;
      height: 50px;
    }
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  //font details
  .banjos-input-font-details {
    display: flex;
    flex-direction: column;
    gap: 6px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    height: 54px;
    margin-bottom: 8px;
    .font-weight-and-size {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: $gray-50;
      height: 32px;
    }
  }
  .form-check-input:checked {
    background-color: #5e7360;
  }
}
.banjos-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background-color: #ffffff;
  color: $base-color;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  padding: 10px 32px 10px 16px;
  outline: 1px solid $base-color;
  border: none;
  border-radius: 8px;
  background: url("../images/arrow-drop-down.svg");
  filter: invert(43%) sepia(9%) saturate(849%) hue-rotate(75deg) brightness(94%)
    contrast(82%);
  background-repeat: no-repeat;
  background-position: calc(100% + -8px);
  appearance: none;
  max-width: 227px;

  &:focus {
    box-shadow: 0 0 0 0.25rem rgba($base-color, 0.45);
  }
}
